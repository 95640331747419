import { UI_BORDER_LIGHT_COLOR_NAME } from "styles/color-consts";
const cards = [
    {
        fullParagraph: {
            topic: {
                text: "01"
            },
            title: "Set marketing goals & strategy",
            body: "Work collaboratively with internal and external stakeholders to define high-level goals and strategies."
        },
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/marketing%20solution/3-Marketing_card.png"
    },
    {
        fullParagraph: {
            topic: {
                text: "02"
            },
            title: "Set marketing goals & strategy",
            body: "Work collaboratively with internal and external stakeholders to define high-level goals and strategies."
        },
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/marketing%20solution/4-Marketing_card.png"
    },
    {
        fullParagraph: {
            topic: {
                text: "03"
            },
            title: "Set marketing goals & strategy",
            body: "Work collaboratively with internal and external stakeholders to define high-level goals and strategies."
        },
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/marketing%20solution/2-Marketing_card.png"
    },
    {
        fullParagraph: {
            topic: {
                text: "04"
            },
            title: "Set marketing goals & strategy",
            body: "Work collaboratively with internal and external stakeholders to define high-level goals and strategies."
        },
        src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/work%20management_new%20product%20page/marketing%20solution/5-Marketing_card.png"
    }
];
export default {
    borderColor: UI_BORDER_LIGHT_COLOR_NAME,
    cards
};
